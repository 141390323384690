import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Container from "../components/Container";
import H1 from "../components/H1";
import H2 from "../components/H2";
import H3 from "../components/H3";
import H5 from "../components/H5";
import Lead from "../components/Lead";
import Button from "../components/Button";
import Paragraph from "../components/Paragraph";

import CreateMP4 from "../../static/videos/create.mp4";
import TryMP4 from "../../static/videos/try.mp4";
import ManageMP4 from "../../static/videos/manage.mp4";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const HeaderImage = styled.div`
  background-size: contain;
  width: 100%;
  transform: translateX(3vw);
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`;

const DragDrop = styled.div`
  background-color: #fafbff; /* #f8f9fd; */
  position: relative;
  position: relative;
`;

const OverlayTop = styled.div`
  z-index: 2;
  height: 16px;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(
    -180deg,
    rgba(159, 184, 198, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

const OverlayBottom = styled.div`
  z-index: 2;
  height: 16px;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(
    0deg,
    rgba(159, 184, 198, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

const Video = styled.video`
  width: 100%;
  margin-bottom: -3px;
  pointer-events: none;
`;

const TryVideo = styled.video`
  width: 100%;
  pointer-events: none;
  overflow: hidden;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ButtonInfo = styled.div`
  opacity: 0.5;
  font-size: 0.8em;
  line-height: 1.5;
  margin-left: 1rem;
`;

export const IndexPageTemplate = ({
  title,
  headerImage,
  lead,
  cta,
  features,
  firstSection,
  priceSection
}) => (
  <>
    <Helmet title={`Appmantle - Makkelijk zelf apps maken`}>
      <meta name="description" content="Appmantle is een nieuwe manier van apps maken. Een complete moderne app, die je zelf snel & makkelijk in elkaar zet, zonder programmeerkennis." />
    </Helmet>
    <Container>
      <Flex alignItems="center" flexWrap="wrap-reverse" mt={[0, 3, 4]}>
        <Box width={[1, 3 / 5, 1 / 2]}>
          <H1 as="h1">{title}</H1>
          <Lead>{lead}</Lead>
          <ButtonGroup>
            <Button
              as={OutboundLink}
              primary
              large
              href="https://dashboard.appmantle.com/signup"
              withArrow
            >
              {cta}
            </Button>
            <ButtonInfo>
              Geheel vrijblijvend
              <br />
              Je betaalt pas bij publicatie
            </ButtonInfo>
          </ButtonGroup>
        </Box>
        <Box width={[1, 2 / 5, 1 / 2]} px={[4, 0, 0]}>
          <HeaderImage
            style={{
              backgroundImage: `url(${
                !!headerImage.childImageSharp
                  ? headerImage.childImageSharp.fluid.src
                  : headerImage
              })`
            }}
          />
        </Box>
      </Flex>
      <Flex mt={[5, 6, 6]} mb={[4, 6, 6]} flexWrap="wrap">
        <Box width={[1, 2 / 5, 2 / 5]} pb={[4, 0, 0]}>
          <H2>Wat kan Appmantle voor mij betekenen?</H2>
          <Paragraph>
            Appmantle is een dienst waarmee je zelf makkelijk apps kan maken. Zo
            zet je jouw idee in een handomdraai om in een app.
          </Paragraph>
          <Button large as={Link} to="/product/">
            Meer informatie
          </Button>
        </Box>
        <Box width={[1, 3 / 5, 3 / 5]} pl={[0, 5, 6]}>
          <Box>
            <H3>De beste gebruikerservaring</H3>
            <Paragraph>
              Appmantle biedt de beste gebruikerservaring voor jouw gebruikers
              of klanten. De apps van Appmantle zijn supersnel in gebruik,
              altijd up-to-date en zijn gebouwd op de laatste en bewezen
              technieken.
            </Paragraph>
          </Box>
          <Box pt={[4, 5, 5]}>
            <H3>Een complete dienst</H3>
            <Paragraph>
              Appmantle biedt een complete dienst voor jouw app. Met Appmantle
              kan je altijd gratis jouw app aanpassen, de content beheren en
              statistieken van de app inzien.
            </Paragraph>
          </Box>
          <Box pt={[4, 5, 5]}>
            <H3>Gratis te proberen</H3>
            <Paragraph>
              Niemand wil ergens aan vast zitten zonder eerst te proberen. Begin
              nu gratis met jouw app. Probeer en experimenteer zo lang als je
              wil. Je kunt jouw gemaakte app zelfs eenvoudig testen op je eigen
              telefoon.
            </Paragraph>
          </Box>
        </Box>
      </Flex>
    </Container>
    <DragDrop>
      <Container>
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]}>
            <TryVideo autoPlay muted loop playsInline>
              <source src={CreateMP4} type="video/mp4" />
              I'm sorry; your browser doesn't support HTML5 video in WebM with
              VP8/VP9 or MP4 with H.264.
            </TryVideo>
          </Box>
          <Box
            width={[1, 1, 1 / 2]}
            pl={[0, 0, 5]}
            pt={[4, 4, 0]}
            pb={[5, 5, 0]}
          >
            <H2 as="h2">Creëer</H2>
            <Paragraph>
              Met Appmantle Studio kies je een stijl die bij jou past en kies je
              uit miljoenen kleurcombinaties. Maak vervolgens pagina’s aan en
              sleep bijvoorbeeld de agenda-, prijs- of locatiemodule naar jouw
              app.
            </Paragraph>
            <Flex mt={4} flexWrap="wrap">
              <Box pr={[3, 4, 4]} width={[1, 1 / 2, 1 / 2]}>
                <H5>Live weergave</H5>
                <Paragraph small>
                  Door intuïtief te klikken en te slepen zie je direct op je
                  computer hoe de app eruit komt te zien.
                </Paragraph>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <H5>Steeds meer modules</H5>
                <Paragraph small>
                  Kies nu al uit een rijke variatie aan modules. Dat worden er
                  binnenkort alleen nog maar meer!
                </Paragraph>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
      <OverlayTop />
      <OverlayBottom />
    </DragDrop>
    <Container>
      <Flex alignItems="center" flexWrap="wrap-reverse" py={[5, 6, 6]}>
        <Box width={[1, 1, 1 / 2]} pr={[0, 0, 4]} py={[4, 4, 0]}>
          <H2 as="h2">Probeer</H2>
          <Paragraph>
            Je kunt jouw app geheel vrijblijvend proberen op je eigen telefoon.
            Download de Appmantle app en log in met je eigen account.
          </Paragraph>
          <Flex mt={4} flexWrap="wrap">
            <Box pr={[3, 4, 4]} width={[1, 1 / 2, 1 / 2]}>
              <H5>Direct zichtbaar</H5>
              <Paragraph small>
                Nog niet helemaal tevreden? Als je iets aanpast, ontvang je
                direct een melding op je telefoon.
              </Paragraph>
            </Box>
            <Box width={[1, 1 / 2, 1 / 2]}>
              <H5>Gratis & vrijblijvend</H5>
              <Paragraph small>
                Probeer zo vaak je wil, zolang je wil. Pas wanneer je besluit de
                app echt te gaan publiceren, vragen wij om de benodigde
                gegevens.
              </Paragraph>
            </Box>
          </Flex>
        </Box>
        <Box width={[1, 1, 1 / 2]} pl={[0, 4, 4]}>
          <TryVideo autoPlay muted loop playsInline>
            <source src={TryMP4} type="video/mp4" />
            I'm sorry; your browser doesn't support HTML5 video in WebM with
            VP8/VP9 or MP4 with H.264.
          </TryVideo>
        </Box>
      </Flex>
    </Container>
    <DragDrop>
      <Container>
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={[1, 1, 1 / 2]}>
            <Video autoPlay muted loop playsInline>
              <source src={ManageMP4} type="video/mp4" />
              I'm sorry; your browser doesn't support HTML5 video in WebM with
              VP8/VP9 or MP4 with H.264.
            </Video>
          </Box>
          <Box
            width={[1, 1, 1 / 2]}
            pl={[0, 0, 5]}
            pt={[4, 4, 0]}
            pb={[5, 5, 0]}
          >
            <H2 as="h2">Beheer</H2>
            <Paragraph>
              Na het publiceren beheer je net zo eenvoudig je content. Voeg
              moeiteloos nieuwe artikelen, locaties of evenementen toe. Pas je
              app op elk gewenst moment aan.
            </Paragraph>
            <Flex mt={4} flexWrap="wrap">
              <Box pr={[3, 4, 4]} width={[1, 1 / 2, 1 / 2]}>
                <H5>Statistieken</H5>
                <Paragraph small>
                  Met de real-time statistieken heb je altijd en overal inzicht
                  in het gebruik van jouw app.
                </Paragraph>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2]}>
                <H5>Updates direct beschikbaar</H5>
                <Paragraph small>
                  De meeste wijzigingen zijn direct zichtbaar, zonder dat jouw
                  gebruikers een update hoeven te downloaden.
                </Paragraph>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <OverlayTop />
        <OverlayBottom />
      </Container>
    </DragDrop>
  </>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  headerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  lead: PropTypes.string,
  features: PropTypes.array,
  intro: PropTypes.shape({
    features: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        headerImage={frontmatter.headerImage}
        title={frontmatter.title}
        lead={frontmatter.lead}
        cta={frontmatter.cta}
        features={frontmatter.features}
        firstSection={frontmatter.firstSection}
        priceSection={frontmatter.priceSection}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        lead
        cta
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        features {
          title
          description
          icon {
            publicURL
          }
        }
        firstSection {
          title
          description
          feature1 {
            title
            description
          }
          feature2 {
            title
            description
          }
        }
        priceSection {
          free {
            title
            description
            cta
          }
          price {
            price
            description
            cta
          }
        }
      }
    }
  }
`;
